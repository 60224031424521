<template>
  <div class="meta-nav__wrapper">
    <div class="meta-nav__navigations container">
      <nav class="meta-nav" :aria-label="ariaLabel">
        <ul class="meta-nav__link-list">
          <li v-for="(metaLink, index) in links" :key="index" class="meta-nav__link-item">
            <spar-link
              class="meta-nav__link-content bold-hover"
              :data-label="metaLink.label"
              v-bind="metaLink"
              :tosca-prefix="`meta-nav-${metaLink.label?.toLowerCase()}`"
            />
          </li>
        </ul>
        <div class="meta-nav__link-item-leaflet">
          <spar-link
            class="meta-nav__link-content bold-hover"
            :link="getPath('leaflets')"
            :label="$t('header.leaflets.label')"
            icon="flyer"
            :variant="LinkVariant.linkIcon"
            tosca-prefix="meta-nav-current-leaflets"
          />
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LinkVariant, SparLink, type SparLinkProps } from "~/components/shared";
import type { SparMetaNavProps } from "./SparMetaNav.types";

const { $t } = useI18n();
const { getPath } = useRoutes();

defineProps({
  ariaLabel: {
    type: String,
    default: "",
  },
  links: {
    type: Array as PropType<SparLinkProps[]>,
    default: () => [],
  },
}) as SparMetaNavProps;
</script>

<style lang="scss">
@use "./SparMetaNav.scss";
</style>

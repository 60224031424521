<template>
  <div class="layout--default content">
    <spar-header v-if="pageHeader" v-bind="pageHeader"></spar-header>
    <slot />
    <spar-footer v-if="pageFooter" v-bind="pageFooter"></spar-footer>
    <spar-notification />
    <spar-back-to-top />
  </div>
</template>

<script lang="ts" setup>
import { SparFooter, SparHeader } from "~/components/sections";
import { SparBackToTop, SparNotification } from "~/components/shared";
import { useLayout } from "~/composables/layout/useLayout";

const { getPageFooter, getPageHeader } = useLayout();

const pageFooter = await getPageFooter();
const pageHeader = await getPageHeader();
</script>

<style lang="scss">
@use "./default.scss";
</style>

<template>
  <div v-if="navigation.nodes" ref="clickOutsideTarget" class="main-navigation">
    <nav class="main-navigation__wrapper" :aria-label="ariaLabel">
      <ul class="main-navigation__list" role="menubar">
        <li
          v-for="(mainNavItem, index) in navigation.nodes"
          :key="mainNavItem.categoryId"
          class="main-navigation__item"
          role="none"
          :class="{
            'main-navigation__item--active': checkActive(mainNavItem),
          }"
          :data-tosca="`header-nav-row1-${index}`"
        >
          <spar-button
            v-if="mainNavItem.childCategories && mainNavItem.childCategories.length"
            class="main-navigation__item-link btn btn--clear f-bold"
            :aria-label="mainNavItem.label"
            :data-label="mainNavItem.label"
            :variant="ButtonVariant.custom"
            @click="
              setMobileMenuActive(mainNavItem);
              setActiveMenu(mainNavItem);
            "
          >
            {{ mainNavItem.label }}
          </spar-button>

          <spar-link
            v-else
            class="main-navigation__item-link f-bold"
            role="menuitem"
            :aria-expanded="checkActive(mainNavItem).toString()"
            :data-label="mainNavItem.label"
            :label="mainNavItem.label"
            :link="mainNavItem.link"
            @click="setActiveMenu(mainNavItem)"
          ></spar-link>

          <spar-sub-navigation
            v-if="metaNav && mainNavItem.childCategories && mainNavItem.childCategories.length"
            :sub-navigation="mainNavItem.childCategories"
            :parent="mainNavItem"
            :flyout-openable="navigation.flyoutOpenable"
            :level-path="navigation.levelPath"
            :active-level="navigation.activeMenuLevel"
            :show-mobile-meta-navigation="checkActive(mainNavItem)"
            :meta-nav="metaNav"
            @close-sub-navigation="closeSubNavigation"
            @close-flyout="closeFlyout"
          >
          </spar-sub-navigation>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import SparSubNavigation from "~/components/sections/SparHeader/SparMainNavigation/SparSubNavigation/SparSubNavigation.vue";
import { ButtonVariant, SparButton, SparLink } from "~/components/shared";
import { useScreenInfo } from "~/composables/utils/useScreenInfo";
import { useNavigationStore } from "~/stores/navigation.store";
import type { SparMetaNavProps } from "../SparMetaNav/SparMetaNav.types";
import type { SparMainNavigationProps } from "./SparMainNavigation.types";

defineProps({
  ariaLabel: {
    type: String,
    default: "",
  },
  metaNav: {
    type: Object as PropType<SparMetaNavProps>,
    default: undefined,
  },
}) as SparMainNavigationProps;

const { isMobile } = useScreenInfo();
const {
  setCurrentMenuLevel,
  navigation,
  setActiveMenu,
  checkActive,
  closeFlyout,
  setMobileMenuActive,
} = useNavigationStore();
const { currentMenuLevel } = storeToRefs(useNavigationStore());

// close navigation on click outside
const clickOutsideTarget = ref(null);
onClickOutside(clickOutsideTarget, () => {
  if (!isMobile.value && currentMenuLevel.value === 3) {
    closeFlyout(null);
  }
});

const closeSubNavigation = () => {
  setCurrentMenuLevel(1);
  navigation.levelPath = [];
};

onMounted(() => {
  if (!navigation?.activeNode) return;
  setActiveMenu(navigation.activeNode, true);
});
</script>

<style lang="scss">
@use "./SparMainNavigation.scss";
</style>

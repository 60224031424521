<template>
  <div class="search-field" :aria-label="ariaLabel">
    <div class="search-field__content">
      <div class="search-field__select" data-tosca="header-search-select">
        <spar-select
          class="search-field__options"
          tosca-prefix="header-search"
          :options="options"
          :value="1"
          show-hint
          :model-value="options[0].key"
          data-tosca="header-search-select"
        ></spar-select>
      </div>
      <div class="search-field__action">
        <form class="search-field__form" @submit.prevent="onSubmit">
          <input
            id="search"
            v-model="query"
            data-tosca="header-search-field-input"
            :placeholder="placeholder"
            :aria-label="placeholder"
            maxlength="100"
            minlength="1"
            name="search"
            type="text"
            class="search-field__input spar-input__input"
            @focusin="
              mobileMenuOpen = true;
              showSearchOverlay = true;
            "
            @input="query.length > 2 ? getResults() : ''"
          />
          <label for="search" class="search-field__input-label">{{ placeholder }}</label>
          <spar-button
            class="search-field__submit-btn btn--clear"
            type="submit"
            icon="search"
            icon-only
            tosca-prefix="header-search-submit"
            :variant="ButtonVariant.custom"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/core";
import { ButtonVariant, SparSelect, SparButton } from "~/components/shared";
import useI18n from "~/composables/i18n/useI18n";
import { useNavigationStore } from "~/stores/navigation.store";
import { useSearchStore } from "~/stores/search.store";

defineProps({
  ariaLabel: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const { $t } = useI18n();
const { mobileMenuOpen } = storeToRefs(useNavigationStore());
const route = useRoute();
const router = useRouter();
const { setRedirectUrl } = useSearchStore();
const { showSearchOverlay } = storeToRefs(useSearchStore());
const { getPath } = useRoutes();
const query = ref("");

const options = $t("header.search.options")
  .split("|")
  .map((value, key) => {
    return {
      key,
      value,
    };
  });

const onSubmit = async () => {
  setRedirectUrl(route.fullPath);
  router.push({ path: getPath("search"), query: { search: query.value } });
};

const getResults = useDebounceFn(async () => {
  Log.log(LogArea.search, `call factfinder and get results ${query}`);
}, 250);

onMounted(() => {
  if (route.path === getPath("search") && typeof route.query.search === "string") {
    query.value = route.query.search;
  }
});
</script>

<style lang="scss">
@use "./SparSearchField.scss";
</style>

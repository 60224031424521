<template>
  <section class="search-flyout">
    <div class="search-flyout__content">
      <div v-if="products" class="search-flyout__products" data-tosca="search-flyout-products">
        <div v-for="product in products" :key="product.mainInfo.productId">
          {{ product.mainInfo.name1 }}
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useSearchStore } from "~/stores/search.store";
import { SearchCategory } from "../SparSearch/SparSearch.types";

const { searchResults } = useSearchStore();

const products = computed(() => {
  const { resolvedProducts } = searchResults[SearchCategory.products];
  if (!resolvedProducts) return [];
  return resolvedProducts.slice(0, 4);
});
</script>

<style lang="scss">
@use "./SparSearchFlyout.scss";
</style>

<template>
  <div class="top-header">
    <div class="top-header__content container">
      <NuxtLink to="/" class="top-header__logo-wrapper"
        ><spar-adaptive-image
          class="top-header__logo-img"
          data-tosca="header-logo"
          :image="logo"
        ></spar-adaptive-image
      ></NuxtLink>
      <spar-search-field class="top-header__search" v-bind="search" />

      <div class="top-header__search-close-wrapper">
        <spar-button
          :variant="ButtonVariant.custom"
          class="top-header__search-close btn--secondary"
          icon="close"
          icon-only
          tosca-prefix="top-header-search-close"
          @click="
            setMobileMenuActive(null, true);
            showSearchOverlay = false;
          "
        ></spar-button>
      </div>

      <nav role="menu" class="top-header__actions" :aria-label="ariaLabelActions">
        <ul class="top-header__actions-list">
          <li class="top-header__actions-item top-header__actions-item--pos" role="none">
            <spar-button
              class="top-header__action top-header__action-link-content btn--clear"
              tosca-prefix="header-choose-delivery"
              icon="pin"
              icon-position="right"
              :variant="ButtonVariant.custom"
              :label="$t('header.action_links.pos')"
            >
              <span class="top-header__action-label">{{ $t("header.action_links.pos") }}</span>
            </spar-button>
          </li>

          <li class="top-header__actions-item" role="none">
            <spar-link
              data-tosca="header-myaccount"
              class="top-header__action top-header__action-link-content"
              :link="accountLink.link"
              icon="account"
              icon-position="right"
              :label="$t('header.action_links.login')"
              :variant="LinkVariant.linkIcon"
              @click="onMyAccountClick"
            >
              <span class="top-header__action-label">{{ accountLink.text }}</span>
            </spar-link>
          </li>

          <li class="top-header__actions-item" role="none">
            <spar-link
              data-tosca="header-favorite-list"
              class="top-header__action top-header__action-link-content"
              :link="`${getPath('myAccount')}`"
              icon="heart-filled"
              icon-only
              :title="$t('header.action_links.favorite')"
              :variant="LinkVariant.linkIcon"
            />
          </li>

          <template v-for="(store, index) in getCartStores()">
            <li
              v-if="isBaseSiteActive(store().bs)"
              :key="index"
              class="top-header__actions-item"
              role="none"
            >
              <spar-link
                class="top-header__action top-header__action-link-content top-header__action-link-cart"
                :link="`${getPath('cart', true).replace(':baseSite', store().bs)}`"
                icon="cart"
                :title="store().bs"
                :label="$t('header.action_links.cart')"
                :variant="LinkVariant.linkIcon"
                :tosca-prefix="`header-cart-${store().bs}`"
                @click="setContext(store().bs)"
              >
                <span
                  v-if="store().cart?.entries?.length"
                  class="top-header__action-cart-entries"
                  :data-tosca="`header-cart-amount-${store().bs}`"
                  >{{ store().cart?.entries?.length }}</span
                >
                <span
                  v-if="store().bs == 'timeslot'"
                  class="f-size--text-1"
                  style="position: absolute; bottom: -1.5rem; left: 0.25rem"
                  >{{ store().bs.charAt(0).toUpperCase() }}</span
                >
              </spar-link>
            </li>
          </template>
        </ul>
      </nav>
    </div>

    <div v-if="showSearchOverlay">
      <spar-search-flyout class="top-header__flyout-search container"></spar-search-flyout>
      <div class="top-header__flyout-search-backdrop" @click="showSearchOverlay = false"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparSearchFlyout from "~/components/feature/SparSearchFlyout/SparSearchFlyout.vue";
import type { SparSearchFieldProps } from "~/components/sections/SparHeader/SparSearchField/SparSearchField.types";
import SparSearchField from "~/components/sections/SparHeader/SparSearchField/SparSearchField.vue";
import {
  ButtonVariant,
  SparLink,
  SparAdaptiveImage,
  SparButton,
  LinkVariant,
  type SparDamAsset,
} from "~/components/shared";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import useI18n from "~/composables/i18n/useI18n";
import { useAuthStore } from "~/stores/auth.store";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import { useNavigationStore } from "~/stores/navigation.store";
import { useSearchStore } from "~/stores/search.store";
import type { SparTopHeaderProps } from "./SparTopHeader.types";

defineProps({
  logo: {
    type: Object as PropType<SparDamAsset>,
    default: undefined,
  },
  ariaLabelActions: {
    type: String,
    default: "",
  },
  search: {
    type: Object as PropType<SparSearchFieldProps>,
    default: undefined,
  },
}) as SparTopHeaderProps;

const { setContext } = useCheckoutStore();
const authStore = useAuthStore();
const { $t } = useI18n();
const route = useRoute();
const { isBaseSiteActive } = useBaseSiteStore();
const { getPath } = useRoutes();

const { showSearchOverlay } = storeToRefs(useSearchStore());
const { setMobileMenuActive } = useNavigationStore();
const { getCartStores } = useBaseSites();

const accountLink = computed(() => {
  if (authStore.isLoggedIn) {
    return {
      text: $t("header.action_links.my_account"),
      link: getPath("myAccount"),
    };
  }
  return {
    text: $t("header.action_links.login"),
    link: getPath("login"),
  };
});

// Redirect user back to current route after login
const onMyAccountClick = () => {
  if (authStore.isLoggedIn) return;
  if (route.path === getPath("login")) return;
  if (route.meta.denyLoginRedirect) return;

  authStore.setRedirectUrl(route.path);
};
</script>

<style lang="scss">
@use "./SparTopHeader.scss";
</style>
